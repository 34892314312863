import './sidebar.style.css';
import React from 'react';

const Sidebar = ({ setOpen, open }) => {
  return (
    <div className='side-bar'>
      <div className='row'>
        <button aria-label='' type='button' onClick={() => setOpen('clients')} className={`${open === 'clients' ? 'active' : ''}`}>clients</button>
        <button aria-label='' type='button' onClick={() => setOpen('mixes')} className={`${open === 'mixes' ? 'active' : ''}`}>mixes</button>
        <button aria-label='' type='button' onClick={() => setOpen('locations')} className={`${open === 'locations' ? 'active' : ''}`}>locations</button>
      </div>
    </div>
  );
};

export default Sidebar;
