import './item.style.css';
import React from 'react';

const Item = ({ mix }) => {
  return (
    <div className='item' dir='rtl'>
      <h3><b>اسم الخلطة: </b><strong style={{ marginLeft: 10, fontWeight: 600 }}>{mix.name}</strong></h3>
      <div>
        <p>
          المالك:  
          <strong style={{ marginLeft: '10px' }}>
            {mix.creator}
          </strong>
        </p>
        <p>
          المكونات: 
          
          <strong style={{ marginLeft: '10px' }}>
            {mix.ingredients.join(", ")}
          </strong>
        </p>
        <p>
          التقييم: 
          <strong style={{ marginLeft: '10px' }}>
            {mix.rate}
          </strong>
        </p>
      </div>
    </div>
  );
};

export default Item;
