import React from 'react';

const Auth = () => {
  return (
    <div>
      <div className="auth-wrapper">
        <div className="auth-form-wrapper">
          <div className="auth-media">
            <img alt='auth-icon' src="https://42f2671d685f51e10fc6-b9fcecea3e50b3b59bdc28dead054ebc.ssl.cf5.rackcdn.com/illustrations/account_490v.svg" />
          </div>
          <div className="auth-form">
            <div className="auth-form-label">
              <p className="primary-label">Sign in</p>
            </div>
            <div className="social-container">
              {/* <a href="#" className="social"><i className="fab fa-facebook-f"></i></a> */}
              <button className="social"><i className="fab fa-google-plus-g"></i></button>
              {/* <a href="#" className="social"><i className="fab fa-linkedin-in"></i></a> */}
            </div>
            {/* <!--	<form> --> */}
              <div>
                <input type="text" name="" placeholder="Username / Email" />
              </div>

              <div>
                <input type="password" name="" placeholder="Password" />
              </div>

              <div className="auth-links-container">
                <p className="auth-fp-link">Forgot password?</p>
              </div>

              <div>
                <input type="submit" name="" value="SIGN IN" />
              </div>
              {/* <!--	</form> --> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
