import React from 'react';

const Clients = ({ searchQuery, clients }) => {
  return (
    <>
      {
        searchQuery.length > 0 ? (
          clients.filter(client => client.name.includes(searchQuery) || client.phone.includes(searchQuery) || client.location.includes(searchQuery)).map((mix, index) => (
            <div className='item' key={index}>
              <h3><b>name:</b><strong style={{ marginLeft: 10, fontWeight: 600 }}>{mix.name}</strong></h3>
              <div>
                <p>
                  phone:
                  <strong style={{ marginLeft: '10px' }}>
                    {mix.phone}
                  </strong>
                </p>
                <p>
                  location:
                  <strong style={{ marginLeft: '10px' }}>
                    {mix.location}
                  </strong>
                </p>
              </div>
            </div>
          ))
        ) : (
          clients.map((mix, index) => (
            <div className='item' key={index}>
              <h3><b>name:</b><strong style={{ marginLeft: 10, fontWeight: 600 }}>{mix.name}</strong></h3>
              <div>
                <p>
                  phone:
                  <strong style={{ marginLeft: '10px' }}>
                    {mix.phone}
                  </strong>
                </p>
                <p>
                  location:
                  <strong style={{ marginLeft: '10px' }}>
                    {mix.location}
                  </strong>
                </p>
              </div>
            </div>
          ))
        )
      }
    </>
  );
};

export default Clients;
