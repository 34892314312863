import React from 'react';
import Item from '../Items/Item/Item';

const Mixes = ({ searchQuery, mixs }) => {
  
  return (
    <>
      {
        searchQuery.length > 0 ? (
          mixs.filter(mix => mix.name.includes(searchQuery) || mix.creator.includes(searchQuery) || mix.ingredients.includes(searchQuery)).map((mix, index) => (
            <Item mix={mix} key={index} />
          ))
        ) : (
          mixs.map((mix, index) => (
            <Item mix={mix} key={index} />
          ))
        )
      }
    </>
  );
};

export default Mixes;
