import './App.css';

// import AOS from 'aos';
// import 'aos/dist/aos.css'; // You can also use <link> for styles

import Auth from './components/auth/Auth';
import Home from './components/home/Home';
// import ErrorPage from './components/ErrorPage/ErrorPage';

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from 'react-router-dom';

// import Home from './components/Home/Home';


// AOS.init();

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='' errorElement={<></>}>
      <Route
        path='/'
        element={<Home />}
      />
      <Route
        path='/auth'
        element={<Auth />}
      />
    </Route>
  )
)

function App() {
  return (
    <RouterProvider router={router} />
  )
}

export default App;
