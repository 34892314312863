import React from 'react';

const Location = ({ mix }) => {
  const filterLocations = (mix) => {
    const locations = [];
    mix.locations.forEach((l) => {
      const location = locations.find(loc => loc.name === l);
      if (location) {
        location.num += 1;
      } else {
        locations.push({ name: l, num: 1 });
      }
    })

    console.log(locations);

    return locations;
  }

  return (
    <div className='item location' dir='rtl'>
      <div>
        <h3><strong style={{ fontWeight: 600 }}>{mix.name}</strong></h3>
        <p>
          {mix.ingredients.map(i => (<p>{i}</p>))}
        </p>
        <h3 style={{
          fontSize: 16,
          fontWeight: 600,
          textTransform: 'capitalize'
        }}>
             اجمالي عدد المستخدمين:  
            {mix.locations.length}
        </h3>
      </div>
      <div className='locations'>
        <h4 style={{ fontWeight: 600 }}>اكثر الاماكن استخداماً</h4>
        <div>
          {filterLocations(mix).map((l) => (
            <>
              <p style={{ fontSize: 16 }}>{l.name}, {l.num}</p>
            </>
          ))}
        </div>
      </div>
    </div>
  )
}

const Locations = ({ searchQuery, mixes }) => {
  return (
    <>
      {
        searchQuery.length > 0 ? (
          mixes.filter(mix => mix.name.includes(searchQuery) || mix.creator.includes(searchQuery) || mix.ingredients.includes(searchQuery) || mix.locations.map(l => l.toLocaleLowerCase()).includes(searchQuery.toLocaleLowerCase())).map((mix, index) => (
            <Location key={index} mix={mix} />
          ))
        ) : (
          mixes.map((mix, index) => (
            <Location key={index} mix={mix} />
          ))
        )
      }
    </>
  );
};

export default Locations;
