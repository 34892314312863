import './Home.style.css';
import React, { useState } from 'react';
import Mixes from '../mixes/mixes';
import Sidebar from '../sidebar/Sidebar';
import Clients from '../Clients/Clients';
import Locations from '../Locations/Locations';

const Home = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [openFilter, setOpenFilter] = useState(false);
  const [open, setOpen] = useState('clients');
  const [filter, setFilter] = useState('');

  const mixs = [
    {
      "name": "خلطة 1",
      "creator": "mohamed",
      "ingredients": ["ارز", 'قشر بيض', 'درة صفرا', "نخالة"],
      'rate': 5,
      'locations': ['sohag', 'minua', 'asuite', 'sohag', 'minua', 'sohag', 'asuite', 'sohag', 'minua', 'asuite']
    },
    {
      "name": "خلطة 2",
      "creator": "mohamed",
      "ingredients": ["مسحوق سمك", 'مسحوق برسيم', 'صويا', "نخالة"],
      'rate': 10.5,
      'locations': ['sohag', 'minua', 'asuite', 'sohag', 'minua', 'sohag', 'asuite', 'sohag', 'minua', 'asuite', 'sohag', 'minuy', 'luxor']
    },
    {
      "name": "خلطة 3",
      "creator": "mohamed",
      "ingredients": ["مسحوق حجر جيري", "ذرة صفراء", "ثنائي فوسفات الكالسيوم", "زيت صويا خام", "بيكربونات صوديوم", "ملح طعام", "مخلوط فيتامينات وأملاح معدنية ( بريمكس تسمين )", "جلوتين ذرة", "كسب فول صويا"],
      'rate': 10.5,
      'locations': ['sohag', 'minua', 'asuite', 'sohag', 'minua', 'sohag', 'minua', 'asuite', 'sohag', 'minuy', 'luxor']
    },
    {
      "name": "خلطة 4",
      "creator": "mohamed",
      "ingredients": ["ذرة صفراء", "زيت صويا خام", "ملح طعام", "مخلوط فيتامينات وأملاح معدنية ( بريمكس تسمين )", "جلوتين ذرة", "كسب فول صويا"],
      'rate': 10.5,
      'locations': ['sohag', 'minua', 'asuite', 'sohag', 'minua', 'sohag', 'minua', 'asuite', 'sohag', 'minuy', 'luxor']
    },
    {
      "name": "خلطة 5",
      "creator": "mohamed",
      "ingredients": ["ذرة صفراء", "زيت صويا خام", "ملح طعام", "مخلوط فيتامينات وأملاح معدنية", "جلوتين ذرة", "فول صويا"],
      'rate': 10.5,
      'locations': ['sohag', 'minua', 'asuite', 'sohag', 'minua', 'sohag', 'minua', 'asuite', 'sohag', 'luxor']
    },
    {
      "name": "خلطة 6",
      "creator": "mohamed",
      "ingredients": ["ذرة صفراء", "زيت صويا خام", "مخلوط فيتامينات وأملاح معدنية", "جلوتين ذرة", "فول صويا"],
      'rate': 10.5,
      'locations': ['sohag', 'minua', 'asuite', 'sohag', 'minua', 'sohag', 'minua', 'asuite', 'sohag', 'luxor', 'minua', 'asuite', 'minua', 'asuite']
    },
  ]

  const clients = [
    { "name": "John do", "phone": "0154554548", "location": "Sohag" },
    { "name": "Mohamed khalied", "phone": "0154554548", "location": "Sohag" },
    { "name": "Ahmed Nasor", "phone": "0154554548", "location": "luxor" },
    { "name": "Yaseen Ahmed", "phone": "0154554548", "location": "Minya" },
    { "name": "Hassan Hamed", "phone": "0154554548", "location": "Minya" },
    { "name": "Muhamad Mamdoh", "phone": "0154554548", "location": "Quna" },
    { "name": "Jack willes", "phone": "0154554548", "location": "Sohag" },
    { "name": "Yousofe Adel", "phone": "0154554548", "location": "Luxor" },
    { "name": "Am Awad", "phone": "0154554548", "location": "Asuete" },
    { "name": "Zezo Mansor", "phone": "0154554548", "location": "Aswan" },
  ]

  return (
    <div className='container home'>
      <div className='header'>
        <input type="search" className='search-input' placeholder='search' onChange={(e) => setSearchQuery(e.target.value)} />
        <button className='filter' type='button' aria-label='filter button' onClick={() => setOpenFilter(of => !of)}>
            filter
        </button>
        {openFilter && (
          <div className='menu-content'>
            <div className='row'>
              <button onClick={() => setFilter('most used')}>most used</button>
              <button onClick={() => setFilter('minimum used')}>minimum used</button>
              <button onClick={() => setFilter('price')}>price</button>
              <button onClick={() => setFilter('top rated')}>top rated</button>
            </div>
          </div>
        )}
      </div>


      
      <Sidebar setOpen={setOpen} open={open} />
  
      <div className='data-container'>
        <div className='items'>
          {open === 'mixes' && (
            <Mixes searchQuery={searchQuery} mixs={mixs} />
          )}
          {open === 'clients' && (
            <Clients searchQuery={searchQuery} clients={clients} />
          )}
          {open === 'locations' && (
            <Locations searchQuery={searchQuery} mixes={mixs} />
          )}
        </div>
      </div>


    </div>
  );
};

export default Home;
